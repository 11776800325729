import React from "react"
import { Link } from "gatsby"

import Image from "../image"
import SignupModalTrigger from '../home/signupModal'

const CTA = ({type}) => {

  return (
    <div className="bg-cyan-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-2">
      <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
        <div className="lg:self-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            {/*<span className="block">Try GummySearch</span>*/}
            <span className="block">Discover your audience</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-cyan-200">
            GummySearch is an audience research toolkit for 130,000 unique communities on Reddit. 
          </p>
          <p className="mt-4 text-lg leading-6 text-cyan-200">
            If you are looking for startup problems to solve, want to validate your idea or find your customers online, GummySearch is for you.
          </p>
          <p className="mt-4 text-lg leading-6 text-cyan-200">
            Sign up for free, get community insights in minutes.
          </p>
          <div className="space-x-4">
            <Link
              to="/product/"
              className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-cyan-600 hover:bg-cyan-50"
            >
              Tell me more
            </Link>
            <SignupModalTrigger initiator="blog-post-cta">
              <div
                // to="/signup/"
                className="mt-8 bg-green-500 cursor-pointer inline-block border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-100 hover:opacity-90"
              >
                Get started
              </div>
            </SignupModalTrigger>
          </div>
        </div>
      </div>
      <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
        <Image fileName="feature-audiences-md.png" alt="Audience Research"
          className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"/>
      </div>
    </div>
  );
}

export default CTA;
